import { toast } from "react-toastify";
import { create } from "zustand";
import { persist } from "zustand/middleware";

import { FIREBASE_AUTH_PERSIST, authRef, singleUserRef } from "config/firebase";

type AuthStore = {
  userData: AdminUser | null;
  isLoading: boolean;

  signIn: (email: string, password: string, navigate: () => void) => void;
  signOut: (navigate: () => void) => void;
  updateUserData: (navigate: () => void) => void;
  updateAccessType: (type: AdminUser["access_type"]) => void;
};

export const useAuthStore = create<AuthStore>()(
  persist(
    (set, get) => ({
      userData: null,
      isLoading: false,

      signIn: async (email, password, navigate) => {
        try {
          set({ isLoading: true });
          await authRef.setPersistence(FIREBASE_AUTH_PERSIST);
          const { user } = await authRef.signInWithEmailAndPassword(
            email,
            password,
          );
          const userDoc = await singleUserRef(user.uid).get();
          const userData = userDoc.data() as AdminUser;
          if (!userDoc.exists) {
            authRef.signOut();
            set({ isLoading: false });
            throw new Error("User not found");
          }

          if (userData?.blocked) {
            authRef.signOut();
            set({ isLoading: false });
            return toast.error("Acesso negado 😕");
          }

          userData.id = user.uid;
          set({ isLoading: false, userData });
          navigate();
        } catch (error) {
          set({ isLoading: false });
          if (error.code === "auth/invalid-email") {
            toast.error("Email inválido 😕");
          } else if (error.code === "auth/user-not-found") {
            toast.error("Endereço de email não cadastrado 😕");
          } else if (error.code === "auth/wrong-password") {
            toast.error("Senha incorreta 😕");
          } else {
            toast.error("Erro ao fazer login 😕");
          }
        }
      },

      signOut: (navigate) => {
        authRef
          .signOut()
          .then(() => {
            navigate();
          })
          .finally(() => {
            set({ userData: null });
          });
      },

      updateAccessType: (type) => {
        const user = get().userData;
        if (user) {
          singleUserRef(user.id)
            .update({ access_type: type })
            .then(() => {
              set({ userData: { ...user, access_type: type } });
            });
        }
      },

      updateUserData: (navigate) => {
        const user = get().userData;

        if (user) {
          singleUserRef(user.id)
            .get()
            .then((doc) => {
              if (doc.exists) {
                const userData = doc.data() as AdminUser;
                userData.id = user.id;
                if (userData.blocked) {
                  get().signOut(navigate);
                }

                set({ userData });
              }
            });
        }
      },
    }),
    {
      name: "auth-store",
      getStorage: () => localStorage,
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(([key]) => ["userData"].includes(key)),
        ),
    },
  ),
);
