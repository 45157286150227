import { memo } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import "./map.css";

// hooks
import { useStores } from "stores";
// config
import { REACT_APP_MAP_KEY } from "config/envs";

export const center = {
  lat: -7.192828,
  lng: -48.204862,
};

const containerStyle = {
  width: "100%",
  height: "100%",
};

export const Map = memo(() => {
  const { driver } = useStores();
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: REACT_APP_MAP_KEY,
    language: "pt-BR",
  });

  const getIcon = (busy: boolean, type: "car" | "moto" = "moto") => {
    return busy
      ? require(`../assets/${type}-pin-busy.png`)
      : require(`../assets/${type}-pin.png`);
  };

  if (!isLoaded) return <div>Carregando...</div>;

  const renderMarkers = () => {
    const locations = driver.driversData
      .map((driver) => {
        if (driver.location) {
          return {
            id: driver.id,
            lat: driver.location.latitude,
            lng: driver.location.longitude,
            drivername: `${driver.first_name}  ${driver.last_name?.[0]}.`,
            busy: driver.busy,
            type: driver.type,
          };
        }

        return undefined;
      })
      .filter((item) => typeof item !== "undefined");

    return locations.map((marker) => (
      <Marker
        position={{ lat: marker.lat, lng: marker.lng }}
        key={marker.id}
        label={{
          text: marker.drivername,
          className: marker.busy ? "label-busy" : "label",
        }}
        options={{
          icon: {
            url: getIcon(marker.busy, marker.type),
            scaledSize: new window.google.maps.Size(40, 40),
          },
        }}
      />
    ));
  };

  return (
    <div style={{ display: "flex", flex: 1 }}>
      <div style={{ height: "400px" }} />
      <GoogleMap center={center} mapContainerStyle={containerStyle} zoom={12.5}>
        {renderMarkers()}
      </GoogleMap>
    </div>
  );
});
