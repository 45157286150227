import { memo, useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import { InputBase, Typography, Toolbar as Bar } from "@material-ui/core";
import { Search } from "@material-ui/icons";

import { useStyles } from "./styles";

type Props = {
  usersCont: number;
  todayUsersCount: number;
  searchByName: (search: string) => void;
  resetData: () => void;
};

export const Toolbar = memo(
  ({ usersCont, todayUsersCount, searchByName, resetData }: Props) => {
    const classes = useStyles();
    const [search, setSearch] = useState("");

    const debounceSearch = useCallback(debounce(searchByName, 600), []);

    useEffect(() => {
      if (search.trim().length > 2) {
        debounceSearch(search);
      }

      if (!search.trim().length) {
        resetData();
      }
    }, [search]);

    return (
      <Bar
        style={{
          minWidth: 800,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" noWrap>
          {`Total cadastrados: ${usersCont.toLocaleString()} - Cadastrados hoje: ${todayUsersCount.toLocaleString()}`}
        </Typography>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <Search />
          </div>
          <InputBase
            placeholder="Buscar…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            inputProps={{ "aria-label": "search" }}
          />
        </div>
      </Bar>
    );
  },
);
