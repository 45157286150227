import { useState } from "react";
import MaterialTable, { Column } from "material-table";
import StarRatings from "react-star-ratings";
import moment from "moment";
import "moment/locale/pt-br";
import { ChatOutlined, Info, LocalTaxi, TwoWheeler } from "@material-ui/icons";

// components
import { ChatModal, RideInfoModal } from "components";
// hooks
import { useStores } from "stores";
// styles
import { AddressText } from "./styles";
// helpers
import { getInfo } from "./helpers";
import { brlConverter } from "helpers";

export function Rides() {
  // hooks
  const { auth, ride } = useStores();

  // state
  const [selectedRide, setSelectedRide] = useState({} as RideData);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showChatModal, setShowChatModal] = useState(false);

  const isFullAccess = auth.userData?.access_type === "full";
  const accessType = auth.userData?.access_type;
  const { title, subTitle, payMode } = getInfo(ride, isFullAccess, accessType);

  const columns: Column<RideData>[] = [
    {
      title: "Data",
      render: (row) => (
        <p>
          {moment(row.created_at.toDate())
            .locale("pt-br")
            .format("ddd, D [de] MMM - H:mm")}
        </p>
      ),
    },
    {
      title: "Cliente",
      field: "rider_name",
    },
    ...(isFullAccess
      ? [
          {
            title: "Tipo",
            field: "type",
            customSort: (a, b) => {
              a.type = a.type || "moto";
              b.type = b.type || "moto";
              return a.type.localeCompare(b.type);
            },
            render: (row) =>
              row.type === "car" ? <LocalTaxi /> : <TwoWheeler />,
          },
        ]
      : []),
    {
      title: "Saída",
      render: (row) => (
        <AddressText>
          {row.start_time && (
            <>
              <b>às {row.start_time}</b> <br />
            </>
          )}
          {row.origin.desc}
        </AddressText>
      ),
    },
    {
      title: "Destino",
      render: (row) => (
        <AddressText>
          {row.end_time && (
            <>
              <b>às {row.end_time}</b> <br />
            </>
          )}
          {row.destination.desc}
        </AddressText>
      ),
    },
    { title: "Piloto", field: "driver_name" },
    {
      title: "Status",
      field: "status",
      render: (row) => {
        switch (row.status) {
          case "END":
            return <strong>Finalizada</strong>;
          case "NEW":
            return <strong>Não Aceita</strong>;
          case "START":
            return <strong>Iniciada</strong>;
          case "CANCELLED":
            return (
              <>
                <strong>Cancelada</strong>
                <br />
                às {row.cancel_time}
                <br />
                Motivo: {row.cancel_reason}
              </>
            );
          case "CANCELLED_PILOT":
            return (
              <>
                <strong>Cancelada pelo piloto</strong>
                <br />
                às {row.cancel_time}
                <br />
                {row.cancel_reason}
              </>
            );
          case "ACCEPTED":
            return <strong>Aceita</strong>;
          case "UNAVALIABLE":
            return <strong>Ignorada, Sem piloto ativo</strong>;
          case "ARRIVAL":
            return (
              <>
                <strong>Chegou no cliente</strong>;
                <br />
                às {row.arrival_time}
              </>
            );
          case "EXPIRED":
            return (
              <>
                <strong>Mais de 4 min de espera</strong>;
                <br />
                corrida cancelada
              </>
            );
          default:
            return <strong>Ignorada</strong>;
        }
      },
    },
    {
      title: "Preço",
      render: (data) => (
        <>
          <b>{brlConverter(data.ride_cost)}</b> <br />
          <span>No {payMode[data.pay_mode]}</span>
        </>
      ),
    },
    {
      title: "Avaliação",
      render: (data) => (
        <StarRatings
          rating={data.ride_rating || 0}
          starRatedColor="#ffd700"
          starDimension="16px"
          starSpacing="1px"
        />
      ),
    },
  ];

  return (
    <>
      <MaterialTable
        title={
          <div>
            <h2>{title}</h2>
            <h3>{subTitle}</h3>
          </div>
        }
        isLoading={ride.isLoading}
        columns={columns}
        data={ride.ridesData}
        localization={{
          toolbar: { searchPlaceholder: "Pesquisar" },
          header: { actions: "Ações" },
          body: {
            editRow: { deleteText: "Excluir esta corrida ?" },
            emptyDataSourceMessage: "Nenhuma corrida encontrada",
          },
          pagination: {
            labelRowsSelect: "Resultados",
            labelDisplayedRows: "{from}-{to} de {count}",
            firstTooltip: "Primeira página",
            previousTooltip: "Página anterior",
            nextTooltip: "Próxima página",
            lastTooltip: "Última página",
          },
        }}
        options={{
          pageSize: 150,
          pageSizeOptions: [20, 50, 150],
          emptyRowsWhenPaging: false,
        }}
        actions={[
          isFullAccess && {
            icon: () => <ChatOutlined />,
            tooltip: "Conversas",
            onClick: (_, rideData: RideData) => {
              setSelectedRide(rideData);
              setShowChatModal(true);
            },
          },
          {
            icon: () => <Info />,
            tooltip: "Info",
            onClick: (_, rideData: RideData) => {
              setSelectedRide(rideData);
              setShowInfoModal(true);
            },
          },
        ]}
        editable={{
          ...(isFullAccess && {
            onRowDelete: (rideData: RideData) =>
              ride.deleteRide(rideData.ride_id),
          }),
        }}
      />
      <RideInfoModal
        open={showInfoModal}
        rideData={selectedRide}
        onClose={() => setShowInfoModal(false)}
      />
      <ChatModal
        open={showChatModal}
        rideId={selectedRide.ride_id}
        onClose={() => setShowChatModal(false)}
      />
    </>
  );
}
