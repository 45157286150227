export const getIsoDate = () => {
  const today = new Date().toLocaleDateString("pt-BR", {
    timeZone: "America/Sao_Paulo",
  });

  const fullDate = today.split("/").reverse().join("-");
  const month = fullDate.split("-")[1];

  return { fullDate, month };
};
