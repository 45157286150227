import { create } from "zustand";
import { toast } from "react-toastify";

import { adminIfoRef, driversRef, ratesRef } from "config/firebase";
import { useDriverStore } from "./driver.store";
import { useAuthStore } from "./auth.store";

type FinancialStore = {
  recharges: Recharge[];
  rates: Rate[];
  isLoading: boolean;
  rechargesAmount: number;
  carRecharges: number;
  motoRecharges: number;
  carRechargesAmount: number;
  motoRechargesAmount: number;

  loadRecharges: () => void;
  loadRates: () => void;
  rechargeDriverBalance: (
    data: DriverData,
    amount: number,
    onClose: () => void,
  ) => Promise<void>;
  deleteRecharge: (id: string) => Promise<void>;
  editRate: (rate: Rate) => Promise<void>;
};

export const useFinancialStore = create<FinancialStore>((set, get) => ({
  recharges: [],
  rates: [],
  isLoading: false,
  rechargesAmount: 0,
  carRecharges: 0,
  motoRecharges: 0,
  carRechargesAmount: 0,
  motoRechargesAmount: 0,

  loadRecharges: () => {
    set({ isLoading: true });
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const authStore = useAuthStore.getState();
    const accessType = authStore.userData?.access_type;

    adminIfoRef
      .collection("recharge_history")
      .orderBy("date", "desc")
      .where("date", ">=", firstDay)
      .get()
      .then((snapshot) => {
        const rechargesData = [];
        let amount = 0;
        let carRechargesAmount = 0;
        let motoRechargesAmount = 0;
        let carRecharges = 0;
        let motoRecharges = 0;

        snapshot.forEach((doc) => {
          const recharge = doc.data() as Recharge;
          recharge.id = doc.id;

          if (accessType !== "full") {
            if (accessType === "car" && recharge.type === "car") {
              rechargesData.push(recharge);
            } else if (accessType === "moto" && recharge.type !== "car") {
              rechargesData.push(recharge);
            }
          } else {
            rechargesData.push(recharge);
          }

          if (recharge.status !== "EXPIRED" && recharge.status !== "PENDING") {
            amount += recharge.amount;

            if (recharge.type === "car") {
              carRechargesAmount += recharge.amount;
              carRecharges++;
            } else {
              motoRechargesAmount += recharge.amount;
              motoRecharges++;
            }
          }
        });

        set({
          rechargesAmount: amount,
          recharges: rechargesData,
          isLoading: false,
          carRecharges,
          motoRecharges,
          carRechargesAmount,
          motoRechargesAmount,
        });
      });
  },

  loadRates: () => {
    set({ isLoading: true });

    ratesRef.get().then((snapshot) => {
      const ratesData = [];

      snapshot.forEach((doc) => {
        const rate = doc.data() as Rate;
        rate.id = doc.id;
        ratesData.push(rate);
      });

      set({ rates: ratesData, isLoading: false });
    });
  },

  editRate: async (rate) => {
    await ratesRef.doc(rate.id).update(rate);
    get().loadRates();
  },

  rechargeDriverBalance: async (driverData, amount, onClose) => {
    try {
      await driversRef.doc(driverData.id).update({
        balance: driverData.balance + amount,
      });
      await driversRef
        .doc(driverData.id)
        .collection("info")
        .doc("current")
        .update({
          balance: driverData.balance + amount,
        });

      const userName = useAuthStore.getState().userData.name;
      const rechargeData = {
        amount,
        operator: userName,
        date: new Date(),
        type: driverData.type || "moto",
        pilot_name: driverData.first_name + " " + driverData.last_name,
      };

      await adminIfoRef.collection("recharge_history").add(rechargeData);
      const driverStore = useDriverStore.getState();
      driverStore.loadDrivers();
      onClose();
      toast("Recarga efetuada", { type: "success" });
    } catch (error) {
      toast("Erro ao fazer recarga", { type: "error" });
    }
  },

  deleteRecharge: async (id: string) => {
    await adminIfoRef.collection("recharge_history").doc(id).delete();
    get().loadRecharges();
  },
}));
