import { useEffect, useRef, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import { Person, SportsMotorsports } from "@material-ui/icons";

import { chatRef } from "config/firebase";

export function ChatModal({ open, rideId, onClose }) {
  const [chatList, setChatList] = useState([]);

  const removeUpdate = useRef(null);

  useEffect(() => {
    if (open && rideId) {
      removeUpdate.current = chatRef
        .doc(rideId)
        .collection("messages")
        .orderBy("date", "asc")
        .onSnapshot((docs) => {
          const messages = [];

          docs.forEach((doc) => {
            messages.push(doc.data());
          });

          setChatList(messages);
        });
    } else {
      setChatList([]);
      removeUpdate.current && removeUpdate.current();
    }
  }, [rideId, open]);

  return (
    <Dialog open={open} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Conversas</DialogTitle>
      <DialogContent
        style={{ display: "flex", flexDirection: "column", minWidth: 350 }}
      >
        {chatList.map((message, index) => (
          <Paper
            key={`message-${index}`}
            style={{
              display: "flex",
              alignSelf:
                message.source === "driver" ? "flex-end" : "flex-start",
              marginBottom: 8,
              maxWidth: 300,
              padding: "4px 8px",
              backgroundColor:
                message.source === "driver" ? "lightgreen" : "lightsalmon",
              borderRadius: 2,
            }}
          >
            {message.source === "driver" ? <SportsMotorsports /> : <Person />}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="body2"
                style={{ marginLeft: 4, marginTop: 2 }}
              >
                {message.message}
              </Typography>
              <Typography variant="caption" style={{ alignSelf: "end" }}>
                {moment(message.date.toDate()).format("HH:mm")}
              </Typography>
            </div>
          </Paper>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          FECHAR
        </Button>
      </DialogActions>
    </Dialog>
  );
}
