import { create } from "zustand";

import { feedRef } from "config/firebase";

type FeedbacksStore = {
  feedbacksData: Feedback[];
  isLoading: boolean;

  loadFeedbacks: () => void;
  deleteFeedback: (rideId: string) => Promise<void>;
};

export const useFeedbacksStore = create<FeedbacksStore>()((set, get) => ({
  feedbacksData: [],
  isLoading: false,

  loadFeedbacks: () => {
    set({ isLoading: true });
    feedRef
      .orderBy("send")
      .get()
      .then((snapshot) => {
        const arr = [];
        if (snapshot.docs.length > 0) {
          snapshot.forEach((doc) => {
            const data = doc.data();
            data.id = doc.id;
            arr.push(data);
          });
        }
        set({ feedbacksData: arr, isLoading: false });
      });
  },

  deleteFeedback: async (id) => {
    await feedRef.doc(id).delete();
    get().loadFeedbacks();
  },
}));
