import {
  Typography,
  ListItemIcon,
  Divider,
  MenuList,
  MenuItem,
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import {
  AttachMoney,
  People,
  Motorcycle,
  ExitToApp,
  ListAlt,
  Dashboard,
  LocalAtm,
  LocalTaxi,
  ChatOutlined,
  AssignmentOutlined,
} from "@material-ui/icons";

// hooks
import { useStores } from "stores";
// assets
import logo from "assets/logo.svg";

type Props = {
  toggleDrawer?: () => void;
};

export function AppMenu({ toggleDrawer }: Props) {
  const { auth } = useStores();
  const history = useHistory();

  const accessType = auth.userData?.access_type;
  const permissionType = auth.userData?.permission_type;
  const menuLabel = {
    car: "Motoristas",
    full: "Pilotos/Motoristas",
    moto: "Pilotos",
  }[accessType];

  const handleNavigate = () => {
    history.push("/login");
  };

  const handlSignOut = () => {
    auth.signOut(handleNavigate);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img
          style={{
            width: "110px",
            height: "110px",
            padding: "10px",
          }}
          src={logo}
          alt="Logo"
        />
      </div>
      <Divider />
      <MenuList>
        <MenuItem component={Link} to="/" onClick={toggleDrawer}>
          <ListItemIcon>
            <Dashboard />
          </ListItemIcon>
          <Typography variant="inherit">Principal</Typography>
        </MenuItem>

        <MenuItem component={Link} to="/rides" onClick={toggleDrawer}>
          <ListItemIcon>
            <ListAlt />
          </ListItemIcon>
          <Typography variant="inherit">Corridas</Typography>
        </MenuItem>
        <MenuItem component={Link} to="/drivers" onClick={toggleDrawer}>
          <ListItemIcon>
            {accessType === "moto" ? <Motorcycle /> : <LocalTaxi />}
          </ListItemIcon>
          <Typography variant="inherit">{menuLabel}</Typography>
        </MenuItem>
        <MenuItem component={Link} to="/users" onClick={toggleDrawer}>
          <ListItemIcon>
            <People />
          </ListItemIcon>
          <Typography variant="inherit">Passageiros</Typography>
        </MenuItem>
        {permissionType !== "operator" && (
          <MenuItem component={Link} to="/reports" onClick={toggleDrawer}>
            <ListItemIcon>
              <AssignmentOutlined />
            </ListItemIcon>
            <Typography variant="inherit">Relatórios</Typography>
          </MenuItem>
        )}
        {permissionType === "full" && (
          <MenuItem component={Link} to="/financial" onClick={toggleDrawer}>
            <ListItemIcon>
              <LocalAtm />
            </ListItemIcon>
            <Typography variant="inherit">Financeiro</Typography>
          </MenuItem>
        )}
        {permissionType === "full" && (
          <MenuItem component={Link} to="/rates" onClick={toggleDrawer}>
            <ListItemIcon>
              <AttachMoney />
            </ListItemIcon>
            <Typography variant="inherit">Preços e Taxas</Typography>
          </MenuItem>
        )}
        {permissionType === "full" && (
          <MenuItem component={Link} to="/feedbacks" onClick={toggleDrawer}>
            <ListItemIcon>
              <ChatOutlined />
            </ListItemIcon>
            <Typography variant="inherit">Feedbacks</Typography>
          </MenuItem>
        )}
        <MenuItem onClick={handlSignOut}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <Typography variant="inherit">Sair</Typography>
        </MenuItem>
      </MenuList>
    </div>
  );
}
