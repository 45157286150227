import { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import moment from "moment";

// firebase
import { ridesRef, driversRef, userRef } from "config/firebase";
// helpers
import { getIsoDate, getTaxFare, rideStatus } from "helpers";
// hooks
import { useStores } from "stores";

type Props = {
  open: boolean;
  rideData: RideData;
  onClose: () => void;
};

export function RideInfoModal({ open, rideData, onClose }: Props) {
  const { auth, ride } = useStores();

  const [pilotsList, setPilotsList] = useState([]);
  const [rideCost, setRideCost] = useState(0);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);

  const isFullAccess = auth.userData?.permission_type === "full";

  useEffect(() => {
    if (open) {
      const { requested_drivers = [], ride_cost } = rideData;

      setPilotsList([]);
      setRideCost(ride_cost);
      setStatus(rideData.status);

      requested_drivers.forEach(async (id) => {
        const doc = await driversRef.doc(id).get();
        if (!doc.exists) return;
        const driver = doc.data();
        setPilotsList((current) => [...current, driver]);
      });
    }
  }, [rideData, open]);

  const enableButton = () => {
    return (
      rideCost > 0 && rideCost !== rideData.ride_cost && !ride.isRequestLoading
    );
  };

  const handleChangeValue = () => {
    ride.changeRideValue(rideData, rideCost);
  };

  const handleUpdateStatus = async () => {
    const rideId = rideData.ride_id;
    const driverId = rideData.driver_id;

    setLoading(true);

    if (status === "END") {
      const driver = await driversRef
        .doc(driverId)
        .collection("info")
        .doc("current")
        .get();
      const {
        month_gains = 0,
        balance = 0,
        today_gains = 0,
        today_count = 0,
        month_count = 0,
        finished_rides = 0,
        total_rides = 0,
      } = driver.data();
      const rideDate = moment(rideData.created_at?.toDate?.()).format(
        "yyy-MM-DD",
      );
      const isToday = getIsoDate().fullDate === rideDate;
      const rideValue = rideData.ride_cost;
      const { tax_fare } = await getTaxFare(rideValue);

      const data = {
        month_gains: month_gains + rideValue,
        month_count: month_count + 1,
        finished_rides: finished_rides + 1,
        total_rides: total_rides + 1,
        balance: Number((balance - tax_fare).toFixed(2)),
        ...(isToday && {
          today_gains: today_gains + rideValue,
          today_count: today_count + 1,
        }),
      };

      await driversRef
        .doc(driverId)
        .collection("info")
        .doc("current")
        .update(data);
      await driversRef.doc(driverId).update({ busy: false, ...data });
    }

    await driversRef
      .doc(driverId)
      .collection("rides")
      .doc(rideId)
      .update({ status });
    await userRef
      .doc(rideData.rider_id)
      .collection("rides")
      .doc(rideId)
      .update({ status });
    await ridesRef.doc(rideId).update({ status });
    setLoading(false);
  };

  const handleChangeText = (text) => {
    if (isNaN(text)) return;
    setRideCost(Number(text));
  };

  const Spacement = () => (
    <div
      style={{
        height: 1,
        width: "100%",
        background: "gray",
        margin: "2px 0",
      }}
    />
  );

  return (
    <Dialog open={open} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Detalhes</DialogTitle>
      <DialogContent style={{ minWidth: 300, maxWidth: "100%" }}>
        <Typography>
          <b>Cliente:</b> {rideData.rider_name} {"<------------->"}
          <b> Contato: </b>
          {rideData.rider_phone}
        </Typography>

        <Typography>
          <b>Piloto:</b> {rideData.driver_name} ({rideData.driver_number})
          {" <------------->"}
          <b> Contato: </b>
          {rideData.driver_contact}
        </Typography>
        <Spacement />
        <Typography>
          <b>Solicitação: </b>
          {moment(rideData.created_at?.toDate?.()).format("H:mm")} -{" "}
          <b>Chegada: </b> {rideData.arrival_time} - <b>Iniciada: </b>{" "}
          {rideData.start_time} - <b>Finalizada: </b> {rideData.end_time}
        </Typography>
        {isFullAccess && (
          <>
            <Spacement />
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography>
                <b>Valor da corrida:</b>{" "}
              </Typography>
              <input
                style={{ fontSize: 16, margin: "0 5px", width: 80 }}
                value={rideCost}
                type="text"
                onChange={({ target }) => handleChangeText(target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                disabled={!enableButton()}
                onClick={handleChangeValue}
              >
                {loading ? <CircularProgress size={20} /> : "Alterar"}
              </Button>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography>
                <b>Status:</b>
              </Typography>
              <select
                value={status}
                onChange={({ target }) => setStatus(target.value)}
                style={{ height: 30, margin: "0 10px", fontSize: 18 }}
              >
                {Object.keys(rideStatus).map((key) => (
                  <option key={key} value={key}>
                    {rideStatus[key]}
                  </option>
                ))}
              </select>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdateStatus}
              >
                {loading ? <CircularProgress size={20} /> : "Atualizar"}
              </Button>
            </div>
          </>
        )}
        <Spacement />
        <Typography>
          <b>Pilotos solicitados: {pilotsList.length}</b>
        </Typography>
        {pilotsList.map((driver) => (
          <div key={driver.email}>
            <Typography>
              <b>{driver.driver_number}</b> - {driver.first_name}{" "}
              {driver.last_name}
            </Typography>
          </div>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          FECHAR
        </Button>
      </DialogActions>
    </Dialog>
  );
}
