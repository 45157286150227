import { RideStore } from "stores/ride.store";

export const getInfo = (
  ride: RideStore,
  isFullAccess: boolean,
  accessType: AdminUser["access_type"],
) => {
  // constants
  const {
    ridesData,
    totalCar,
    totalMoto,
    totalFinished,
    todayFinishedCar,
    todayFinishedMoto,
    totalCancelled,
    totalCancelledCar,
    totalCancelledMoto,
    todayCancelledMoto,
    todayCancelledCar,
    totalCancelledPilot,
    todayFinished,
    totalCarFinished,
    totalMotoFinished,
    todayCancelled,
    todayCancelledPilot,
  } = ride;
  const payMode = {
    pix: "Pix",
    money: "Dinheiro",
    card: "Cartão",
  };
  const driverText = {
    full: "Piloto/Motorista",
    car: "Motorista",
    moto: "Piloto",
  }[accessType];
  let title = `Total: ${ridesData.length.toLocaleString()} | Finalizadas: ${totalFinished}
  | Canceladas: ${totalCancelled} | Canceladas ${driverText}: ${totalCancelledPilot}`;
  let subTitle = `Hoje | Finalizadas: ${todayFinished}
  | Canceladas: ${todayCancelled} | Canceladas ${driverText}: ${todayCancelledPilot}`;

  if (isFullAccess) {
    title = `Total: ${ridesData.length.toLocaleString()} - Moto: ${totalMoto.toLocaleString()} - Carro: ${totalCar.toLocaleString()}
    | Finalizadas: ${totalFinished.toLocaleString()} - Moto: ${totalMotoFinished.toLocaleString()} - Carro: ${totalCarFinished.toLocaleString()}
    | Canceladas: ${totalCancelled.toLocaleString()} - Moto: ${totalCancelledMoto.toLocaleString()} - Carro: ${totalCancelledCar.toLocaleString()}  | Canceladas ${driverText}: ${totalCancelledPilot.toLocaleString()}`;
    subTitle = `Hoje | Finalizadas: ${todayFinished.toLocaleString()} - Moto: ${todayFinishedMoto.toLocaleString()} - Carro: ${todayFinishedCar.toLocaleString()}
    | Canceladas: ${todayCancelled.toLocaleString()} - Moto: ${todayCancelledMoto.toLocaleString()} - Carro: ${todayCancelledCar.toLocaleString()}  | Canceladas ${driverText}: ${todayCancelledPilot.toLocaleString()}`;
  }

  return {
    payMode,
    title,
    subTitle,
  };
};
