import { useEffect } from "react";
import MaterialTable, { Column } from "material-table";

import { CircularLoading } from "components";

import { useStores } from "stores";
import { LocalTaxi, TwoWheeler } from "@material-ui/icons";

export function Rates() {
  const { financial } = useStores();

  useEffect(() => {
    financial.loadRates();
  }, []);

  const columns: Column<Rate>[] = [
    {
      title: "Tipo",
      field: "type",
      type: "string",
      align: "center",
      render: (rowData) =>
        rowData.type === "car" ? <LocalTaxi /> : <TwoWheeler />,
      editable: "never",
    },
    {
      title: "Preço por quilômetro",
      field: "rate_km",
      type: "numeric",
      align: "center",
    },
    {
      title: "Preço base",
      field: "min_rate",
      type: "numeric",
      align: "center",
    },
    {
      title: "Preço min. Final Semana",
      field: "min_price_fds",
      type: "numeric",
      align: "center",
    },
    {
      title: "Preço min. dia",
      field: "min_price_day",
      type: "numeric",
      align: "center",
    },
    {
      title: "Preço min. noite bandeira 1",
      field: "min_price_night_1",
      type: "numeric",
      align: "center",
    },
    {
      title: "Preço min. noite bandeira 2",
      field: "min_price_night_2",
      type: "numeric",
      align: "center",
    },
    {
      title: "Porcentagem",
      field: "tax_rate",
      type: "numeric",
      align: "center",
    },
  ];

  return financial.isLoading ? (
    <CircularLoading />
  ) : (
    <MaterialTable
      title="Tarifas"
      columns={columns}
      data={financial.rates}
      options={{ search: false }}
      localization={{
        header: { actions: "Editar" },
        body: {
          editRow: { deleteText: "Excluir esta tarifa ?" },
          emptyDataSourceMessage: "Nenhuma tarifa encontrada",
        },
      }}
      editable={{
        onRowUpdate: (newData) => financial.editRate(newData),
      }}
    />
  );
}
