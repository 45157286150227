import { create } from "zustand";

import { driversRef, storage } from "config/firebase";
import { getIsoDate } from "helpers";
import { useAuthStore } from "./auth.store";

type DriverStore = {
  driversData: DriverData[];
  isLoading: boolean;
  active: number;
  busy: number;
  carActive: number;
  motoActive: number;
  carBusy: number;
  motoBusy: number;
  pilotsCount: number;
  driversCount: number;

  updateDrivers: () => () => void;
  loadDrivers: () => void;
  updateDriver: (data: DriverData) => Promise<void>;
  deleteDriver: (data: DriverData) => Promise<void>;
};

export const useDriverStore = create<DriverStore>()((set, get) => ({
  driversData: [],
  isLoading: false,
  active: 0,
  busy: 0,
  carActive: 0,
  motoActive: 0,
  carBusy: 0,
  motoBusy: 0,
  pilotsCount: 0,
  driversCount: 0,

  updateDrivers: () => {
    set({ isLoading: true });
    const authStore = useAuthStore.getState();
    const accessType = authStore.userData?.access_type;
    const isFullAccess = accessType === "full";
    const driversref = isFullAccess
      ? driversRef
      : driversRef.where("type", "==", accessType);

    const remove = driversref
      .where("active", "==", true)
      .onSnapshot((snapshot) => {
        const drivers = [];
        let busy = 0;
        let carActive = 0;
        let motoActive = 0;
        let carBusy = 0;
        let motoBusy = 0;

        if (snapshot.docs.length > 0) {
          snapshot.forEach((doc) => {
            const data = doc.data() as DriverData;
            data.id = doc.id;

            if (data.busy) {
              data.type === "car" ? carBusy++ : motoBusy++;
              busy++;
            }
            data.type === "car" ? carActive++ : motoActive++;
            drivers.push(data);
          });
        }

        set({
          isLoading: false,
          driversData: drivers,
          active: drivers.length,
          busy,
          carActive,
          motoActive,
          carBusy,
          motoBusy,
        });
      });

    return remove;
  },

  loadDrivers: () => {
    set({ isLoading: true });
    const authStore = useAuthStore.getState();
    const accessType = authStore.userData?.access_type;
    const isFullAccess = accessType === "full";
    const driversref = isFullAccess
      ? driversRef
      : driversRef.where("type", "==", accessType);

    driversref.get().then((snapshot) => {
      if (snapshot.docs.length > 0) {
        const drivers = [];
        let pilotsCount = 0;
        let driversCount = 0;

        snapshot.forEach((doc) => {
          const data = doc.data();
          const dbDate = data.update_date || "";
          const dbMonth = dbDate.split("-")[1];

          if (data.type === "car") {
            driversCount++;
          } else {
            pilotsCount++;
          }

          data.id = doc.id;
          if (dbMonth === getIsoDate().month) {
            data.month_count = data.month_count || 0;
          } else {
            data.month_count = 0;
          }

          drivers.push(data);
        });

        drivers.sort((a, b) => a.month_count - b.month_count).reverse();
        set({
          driversData: drivers,
          isLoading: false,
          pilotsCount,
          driversCount,
        });
      }
    });
  },

  updateDriver: async (data) => {
    await driversRef.doc(data.id).update(data);
    await driversRef
      .doc(data.id)
      .collection("info")
      .doc("current")
      .update({ active: data.active, approved: data.approved });
    get().loadDrivers();
  },

  deleteDriver: async (data) => {
    await driversRef
      .doc(data.id)
      .delete()
      .then(() => storage.ref(`drivers/profile_image/${data.id}/`).delete());
    get().loadDrivers();
  },
}));
