import { useState, useMemo, useEffect } from "react";
import { Grid, Paper, Typography } from "@material-ui/core";

// components
import { Map, DashboardCard, CircularLoading } from "components";
// hooks
import { useStores } from "stores";
import { brlConverter } from "helpers";

export const Dashboard = () => {
  // hooks
  const { auth, ride, driver } = useStores();

  // state
  const [showDay, setShowDay] = useState(false);
  const [showMonth, setShowMonth] = useState(false);

  // constants
  const { todayGains, todayFinished, totalGains, totalFinished } = ride;
  const accessType = auth.userData?.access_type;
  const permissionType = auth.userData?.permission_type;

  useEffect(() => {
    const removeDrivers = driver.updateDrivers();

    return () => {
      removeDrivers();
    };
  }, []);

  const renderMap = useMemo(() => <Map />, []);

  const getDriverInfo = () => {
    const info = {
      full: `Ativos: ${driver.active} - Carro: ${driver.carActive} - Moto: ${driver.motoActive} | Ocupados: ${driver.busy} - Carro: ${driver.carBusy} - Moto: ${driver.motoBusy}`,
      car: `Ativos: ${driver.carActive} | Ocupados: ${driver.carBusy}`,
      moto: `Ativos: ${driver.motoActive} | Ocupados: ${driver.motoBusy}`,
    };

    return info[accessType];
  };

  return ride.isLoading ? (
    <CircularLoading />
  ) : (
    <div
      style={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} md>
          <DashboardCard
            isVisible={showDay}
            showValues={permissionType !== "operator"}
            showHide={() => setShowDay(!showDay)}
            title={`Finalizadas Hoje: ${todayFinished.toLocaleString()}`}
          >
            {`Movimentação dia: ${
              showDay ? brlConverter(todayGains) : "*****"
            }`}
          </DashboardCard>
        </Grid>
        <Grid item xs={12} md>
          <DashboardCard
            title={`Finalizada Este Mês: ${totalFinished.toLocaleString()}`}
            showValues={permissionType !== "operator"}
            isVisible={showMonth}
            showHide={() => setShowMonth(!showMonth)}
          >
            {`Movimentação mês: ${
              showMonth ? brlConverter(totalGains) : "*****"
            }`}
          </DashboardCard>
        </Grid>
      </Grid>
      <Paper
        style={{
          marginTop: "15px",
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h5" style={{ margin: "15px 0 0 15px" }}>
          Localização | {getDriverInfo()}
        </Typography>
        {renderMap}
      </Paper>
    </div>
  );
};
