import { adminIfoRef, userRef } from "config/firebase";
import moment from "moment";
import { create } from "zustand";

type UsersStore = {
  usersData: UserData[];
  isLoading: boolean;
  todayUsersCount: number;
  usersCount: number;
  loadLimit: number;

  loadUsers: (loadLimit?: number) => void;
  getUsersCount: () => void;
  searchByName: (search: string) => void;
};

export const useUserStore = create<UsersStore>((set) => ({
  usersData: [],
  isLoading: false,
  todayUsersCount: 0,
  usersCount: 0,
  loadLimit: 60,

  loadUsers: (loadLimit = 60) => {
    set({ isLoading: true, loadLimit });

    userRef
      .orderBy("created_at", "desc")
      .limit(loadLimit)
      .get()
      .then((snapshot) => {
        if (snapshot.docs.length > 0) {
          const users = [];
          let todayUsers = 0;
          snapshot.forEach((doc) => {
            const data = doc.data();
            const date = new Date(data.created_at.toDate()).toUTCString();
            const created_at = moment(date).locale("pt-br").format("YYYY-MM-D");
            const today = moment().locale("pt-br").format("YYYY-MM-D");

            if (created_at === today) {
              todayUsers += 1;
            }

            data.id = doc.id;
            users.push(data);
          });

          set({
            todayUsersCount: todayUsers,
            usersData: users,
            isLoading: false,
          });
        }
      });
  },

  getUsersCount: () => {
    adminIfoRef.get().then((res) => {
      set({ usersCount: res.data().users_count });
    });
  },

  searchByName: (search) => {
    set({ isLoading: true });

    userRef
      .where("first_name", ">=", search)
      .where("first_name", "<=", search + "\uf8ff")
      .get()
      .then((snapshot) => {
        const users = [];
        if (snapshot.docs.length > 0) {
          snapshot.forEach((doc) => {
            const data = doc.data();

            data.id = doc.id;
            users.push(data);
          });
        }
        set({ isLoading: false, usersData: users });
      })
      .catch(() => {
        set({ isLoading: false });
      });
  },
}));
