import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";

// helpers
import { brlConverter, getTaxAmount, numberFormater } from "helpers";
import { useStores } from "stores";

type Props = {
  report: ReportData;
};

export function DataTable({ report }: Props) {
  const { auth } = useStores();
  const classes = useStyles();

  const accessType = auth.userData?.access_type;
  const isFull = accessType === "full";

  const finishedRides = {
    full: report.totalFinishedRides,
    car: report.totalCarFinished,
    moto: report.totalMotoFinished,
  };
  const totalGains = {
    full: report.totalGains,
    car: report.totalCarGains,
    moto: report.totalMotoGains,
  };
  const totalCancelledRides = {
    full: report.totalCancelledRides,
    car: report.totalCarCancelled,
    moto: report.totalMotoCancelled,
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {isFull && <TableCell>Total Corridas</TableCell>}
            <TableCell align="center">Finalizadas</TableCell>
            <TableCell align="center">Valor bruto</TableCell>
            <TableCell align="center">
              Ganho sob valor bruto {isFull ? "" : "(4%)"}
            </TableCell>
            {isFull && <TableCell align="center">Recargas</TableCell>}
            <TableCell align="center">Canceladas</TableCell>
            <TableCell align="center">Novos Usuários</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {isFull && (
              <TableCell align="center">
                <b>{numberFormater(report.totalRides)}</b> <br />
                Carro: {numberFormater(report.totalCarRides)} <br />
                Moto: {numberFormater(report.totalMotoRides)}
              </TableCell>
            )}
            <TableCell align="center">
              <b>{numberFormater(finishedRides[accessType])}</b> <br />
              {isFull && (
                <>
                  Carro: {numberFormater(report.totalCarFinished)} <br />
                  Moto: {numberFormater(report.totalMotoFinished)}
                </>
              )}
            </TableCell>
            <TableCell align="center">
              <b>{brlConverter(totalGains[accessType])}</b> <br />
              {isFull && (
                <>
                  Carro: {brlConverter(report.totalCarGains)} <br />
                  Moto: {brlConverter(report.totalMotoGains)}
                </>
              )}
            </TableCell>
            <TableCell align="center">
              {isFull ? (
                <>
                  <b>
                    {brlConverter(
                      getTaxAmount(report.totalCarGains, 6) +
                        getTaxAmount(report.totalMotoGains, 8),
                    )}
                  </b>
                  <br />
                  Carro: {brlConverter(
                    getTaxAmount(report.totalCarGains, 6),
                  )}{" "}
                  (6%)
                  <br />
                  Moto: {brlConverter(
                    getTaxAmount(report.totalMotoGains, 8),
                  )}{" "}
                  (8%)
                </>
              ) : (
                <b>{brlConverter(getTaxAmount(totalGains[accessType], 4))}</b>
              )}
            </TableCell>
            {isFull && (
              <TableCell align="center">
                <b>
                  {brlConverter(report.rechargesAmount)} (
                  {numberFormater(report.carRecharges + report.motoRecharges)})
                </b>
                <br />
                Carro: {brlConverter(report.carRechargesAmount)} (
                {report.carRecharges}) <br />
                Moto: {brlConverter(report.motoRechargesAmount)} (
                {report.motoRecharges})
              </TableCell>
            )}
            <TableCell align="center">
              Cliente: {totalCancelledRides[accessType]} <br />
              {(isFull || accessType === "moto") && (
                <>
                  Piloto: {report.totalMotoCancelledPilot} <br />
                </>
              )}
              {(isFull || accessType === "car") && (
                <>Motorista: {report.totalCarCancelledPilot}</>
              )}
            </TableCell>
            <TableCell align="center">
              {numberFormater(report.newUsers)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 650,
  },
}));
