import { Card, CardContent, Typography, IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

export function DashboardCard({
  title,
  children,
  showHide,
  isVisible,
  showValues = true,
}) {
  return (
    <Card
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div>
        <CardContent>
          <Typography variant="h6">{title}</Typography>
          {showValues && (
            <Typography variant="h5" color="textSecondary">
              {children}
            </Typography>
          )}
        </CardContent>
      </div>
      {showValues && (
        <IconButton onClick={showHide} color="primary" component="span">
          {isVisible ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      )}
    </Card>
  );
}
