import { useState, useEffect } from "react";
import MaterialTable, { Column } from "material-table";
import { AttachMoney, LocalTaxi, TwoWheeler } from "@material-ui/icons";

// components
import { RechargeModal } from "components";
// helpers
import { brlConverter, getIsoDate } from "helpers";
// hooks
import { useStores } from "stores";

export function Drivers() {
  //hooks
  const { driver, auth } = useStores();
  // state
  const [selectedDriver, setSelectedDriver] = useState({} as DriverData);
  const [showRechargeModal, setShowRechargeModal] = useState(false);

  // constants
  const isFullAccess = auth.userData?.permission_type === "full";
  const accessType = auth.userData?.access_type;
  const numberText = {
    car: "CM",
    full: "Colete/CM",
    moto: "Colete",
  }[accessType];

  useEffect(() => {
    driver.loadDrivers();
  }, []);

  const renderGains = (row) => {
    let today_gains = 0;
    let today_count = 0;
    let month_gains = 0;
    let month_count = 0;

    const dbDate = row.update_date || "";
    const today = getIsoDate().fullDate;
    const dbMonth = dbDate.split("-")[1];

    if (dbDate === today) {
      today_gains = typeof row.today_gains === "object" ? 0 : row.today_gains;
      today_count = row.today_count || 0;
    }
    if (dbMonth === getIsoDate().month) {
      month_gains = row.month_gains || 0;
      month_count = row.month_count || 0;
    }

    return (
      <center>
        Hoje: {brlConverter(today_gains)}
        <br />
        {today_count} corridas <br />
        <div style={{ height: 1, width: 90, background: "#ddd" }} />
        Mês: {brlConverter(month_gains)}
        <br />
        {month_count} corridas
      </center>
    );
  };

  const title = {
    car: `Motoristas Cadastrados: ${driver.driversCount}`,
    full: `Cadastrados: ${driver.driversData.length} | Pilotos: ${driver.pilotsCount} | Motoristas: ${driver.driversCount}`,
    moto: `Pilotos Cadastrados: ${driver.pilotsCount}`,
  }[accessType];

  const columns: Column<DriverData>[] = [
    {
      title: "Info",
      editable: "never",
      align: "center",
      render: (rowData) => (
        <img
          alt="Profile"
          src={rowData.profile_image}
          style={{ width: 50, height: 50, borderRadius: "50%" }}
        />
      ),
    },
    {
      title: "Nome",
      field: "first_name",
      editable: isFullAccess ? "always" : "never",
    },
    {
      title: "Sobrenome",
      field: "last_name",
      editable: isFullAccess ? "always" : "never",
    },
    {
      title: numberText,
      field: "driver_number",
      align: "center",
      editable: isFullAccess ? "always" : "never",
    },
    ...(isFullAccess
      ? [
          {
            title: "Tipo",
            field: "type",
            customSort: (a, b) => {
              a.type = a.type || "moto";
              b.type = b.type || "moto";
              return a.type.localeCompare(b.type);
            },
            render: (row) =>
              row.type === "car" ? <LocalTaxi /> : <TwoWheeler />,
          },
        ]
      : []),
    {
      title: "Ganhos",
      editable: "never",
      align: "center",
      render: renderGains,
    },
    { title: "Saldo", field: "balance", editable: "never", align: "center" },
    {
      title: "Autorizado",
      field: "approved",
      type: "boolean",
      align: "center",
    },
    {
      title: "Ativo",
      field: "active",
      type: "boolean",
      align: "center",
      editable: isFullAccess ? "always" : "never",
    },
    {
      title: "Ocupado",
      field: "busy",
      type: "boolean",
      align: "center",
      editable: isFullAccess ? "always" : "never",
    },
    { title: "Avaliação", field: "rating", editable: "never", align: "center" },
    {
      title: "Contato",
      render: (rowData) => (
        <>
          <p>
            <b>Telefone</b> <br />
            {rowData.phone}
            <br />
            <b> Email</b> <br />
            {rowData.email}
          </p>
        </>
      ),
    },
    {
      title: "Mod. Veículo",
      field: "veichle_model",
      align: "center",
      editable: isFullAccess ? "always" : "never",
    },
  ];

  return (
    <>
      <MaterialTable
        title={title}
        isLoading={driver.isLoading}
        columns={columns}
        data={driver.driversData}
        options={{
          pageSize: 150,
          pageSizeOptions: [20, 50, 150],
          emptyRowsWhenPaging: false,
        }}
        localization={{
          toolbar: { searchPlaceholder: "Pesquisar" },
          header: { actions: "Ações" },
          body: {
            editRow: { deleteText: "Excluir este usuário ?" },
            emptyDataSourceMessage: "Nenhum usuário encontrado",
          },
          pagination: {
            labelRowsSelect: "Resultados",
            labelDisplayedRows: "{from}-{to} de {count}",
            firstTooltip: "Primeira página",
            previousTooltip: "Página anterior",
            nextTooltip: "Próxima página",
            lastTooltip: "Última página",
          },
        }}
        actions={[
          ...(isFullAccess
            ? [
                {
                  icon: () => <AttachMoney />,
                  tooltip: "Fazer recarga",
                  onClick: (_, rowData: DriverData) => {
                    setSelectedDriver(rowData);
                    setShowRechargeModal(true);
                  },
                },
              ]
            : []),
        ]}
        editable={{
          onRowUpdate: (data: DriverData) => driver.updateDriver(data),
          ...(isFullAccess && {
            onRowDelete: (data: DriverData) => driver.deleteDriver(data),
          }),
        }}
      />
      <RechargeModal
        open={showRechargeModal}
        driverData={selectedDriver}
        onClose={() => setShowRechargeModal(false)}
      />
    </>
  );
}
