import { create } from "zustand";
import moment from "moment";

import { driversRef, ridesRef, userRef } from "config/firebase";
import { useAuthStore } from "./auth.store";
import { getIsoDate, getTaxFare } from "helpers";
import { toast } from "react-toastify";

export type RideStore = {
  ridesData: RideData[];
  isLoading: boolean;
  isRequestLoading: boolean;
  totalCar: number;
  totalMoto: number;
  totalCarFinished: number;
  totalMotoFinished: number;
  todayFinished: number;
  todayFinishedCar: number;
  todayFinishedMoto: number;
  todayCancelled: number;
  todayCancelledCar: number;
  todayCancelledMoto: number;
  todayCancelledPilot: number;
  totalFinished: number;
  totalCancelled: number;
  totalCancelledCar: number;
  totalCancelledMoto: number;
  totalCancelledPilot: number;
  totalGains: number;
  todayGains: number;
  totalTaxGains: number;
  todayTaxGains: number;
  loadRides: () => () => void;
  setLoading: (isLoading: boolean) => void;
  changeRideValue: (rideData: RideData, rideCost: number) => Promise<void>;
  deleteRide: (rideId: string) => Promise<void>;
};

export const useRideStore = create<RideStore>()((set) => ({
  ridesData: [],
  isLoading: false,
  isRequestLoading: false,
  totalCar: 0,
  totalMoto: 0,
  todayFinished: 0,
  todayFinishedCar: 0,
  todayFinishedMoto: 0,
  todayCancelled: 0,
  todayCancelledCar: 0,
  todayCancelledMoto: 0,
  todayCancelledPilot: 0,
  totalFinished: 0,
  totalCancelled: 0,
  totalCancelledCar: 0,
  totalCancelledMoto: 0,
  totalCancelledPilot: 0,
  totalGains: 0,
  todayGains: 0,
  totalTaxGains: 0,
  todayTaxGains: 0,
  totalCarFinished: 0,
  totalMotoFinished: 0,

  loadRides: () => {
    const today = new Date();
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    const authStore = useAuthStore.getState();
    const accessType = authStore.userData?.access_type;

    set({ isLoading: true });

    const remove = ridesRef
      .orderBy("created_at", "desc")
      .where("created_at", ">=", firstDay)
      .onSnapshot((snapshot) => {
        const rides: RideData[] = [];
        let totalCar = 0;
        let totalMoto = 0;
        let todayFinished = 0;
        let todayFinishedCar = 0;
        let todayFinishedMoto = 0;
        let todayCancelled = 0;
        let todayCancelledPilot = 0;
        let totalFinished = 0;
        let totalCarFinished = 0;
        let totalMotoFinished = 0;
        let totalCancelled = 0;
        let totalCancelledCar = 0;
        let totalCancelledMoto = 0;
        let todayCancelledCar = 0;
        let todayCancelledMoto = 0;
        let totalCancelledPilot = 0;
        let totalGains = 0;
        let todayGains = 0;
        let totalTaxGains = 0;
        let todayTaxGains = 0;

        snapshot.forEach((doc) => {
          const data = doc.data() as RideData;
          const { status, ride_cost, created_at } = data;
          const rideDate = created_at.toDate();
          data.ride_id = doc.id;

          const updateValues = () => {
            if (status === "END") {
              if (rideDate.getDate() === today.getDate()) {
                todayGains += ride_cost;
                todayFinished++;
                if (data.type === "car") {
                  todayFinishedCar++;
                } else {
                  todayFinishedMoto++;
                }
              }
              totalGains += ride_cost;
              totalFinished++;
              if (data.type === "car") {
                totalCarFinished++;
              } else {
                totalMotoFinished++;
              }
            }
            if (status === "CANCELLED") {
              if (rideDate.getDate() === today.getDate()) {
                todayCancelled++;
                if (data.type === "car") {
                  todayCancelledCar++;
                } else {
                  todayCancelledMoto++;
                }
              }
              totalCancelled++;
              if (data.type === "car") {
                totalCancelledCar++;
              } else {
                totalCancelledMoto++;
              }
            }

            if (status === "CANCELLED_PILOT") {
              if (rideDate.getDate() === today.getDate()) {
                todayCancelledPilot++;
              }
              totalCancelledPilot++;
            }
          };

          if (accessType !== "full") {
            if (accessType === "car" && data.type === "car") {
              updateValues();
              rides.push(data);
            } else if (accessType === "moto" && data.type !== "car") {
              rides.push(data);
              updateValues();
            }
          } else {
            updateValues();
            rides.push(data);
          }

          if (data.type === "car") {
            totalCar++;
          } else {
            totalMoto++;
          }
        });

        totalTaxGains = (totalGains * 12) / 100;
        todayTaxGains = (todayGains * 12) / 100;

        set({
          ridesData: rides,
          isLoading: false,
          totalCar,
          totalMoto,
          todayFinished,
          todayFinishedCar,
          todayFinishedMoto,
          todayCancelled,
          todayCancelledCar,
          todayCancelledMoto,
          todayCancelledPilot,
          totalFinished,
          totalCarFinished,
          totalMotoFinished,
          totalCancelled,
          totalCancelledCar,
          totalCancelledMoto,
          totalCancelledPilot,
          totalGains,
          todayGains,
          totalTaxGains,
          todayTaxGains,
        });
      });

    return remove;
  },

  deleteRide: async (rideId) => {
    await ridesRef.doc(rideId).delete();
  },

  changeRideValue: async (rideData, rideCost) => {
    set({ isRequestLoading: true });

    try {
      const rideId = rideData.ride_id;
      const driverId = rideData.driver_id;

      await driversRef
        .doc(driverId)
        .collection("rides")
        .doc(rideId)
        .update({ ride_cost: rideCost });

      await userRef
        .doc(rideData.rider_id)
        .collection("rides")
        .doc(rideId)
        .update({ ride_cost: rideCost });

      await ridesRef.doc(rideId).update({ ride_cost: rideCost });
      const driver = await driversRef
        .doc(driverId)
        .collection("info")
        .doc("current")
        .get();
      const { month_gains, balance, today_gains } = driver.data();
      const rideDate = moment(rideData.created_at?.toDate?.()).format(
        "yyy-MM-DD",
      );
      const isToday = getIsoDate().fullDate === rideDate;
      let data = {};

      if (rideCost > rideData.ride_cost) {
        const differenceValue = rideCost - rideData.ride_cost;
        const { tax_fare } = await getTaxFare(differenceValue, rideData.type);

        data = {
          month_gains: month_gains + differenceValue,
          balance: Number((balance - tax_fare).toFixed(2)),
          ...(isToday && {
            today_gains: today_gains + differenceValue,
          }),
        };
      } else {
        const differenceValue = rideData.ride_cost - rideCost;
        const { tax_fare } = await getTaxFare(differenceValue, rideData.type);

        data = {
          month_gains: month_gains - differenceValue,
          balance: Number((balance + tax_fare).toFixed(2)),
          ...(isToday && {
            today_gains: today_gains - differenceValue,
          }),
        };
      }

      console.log({ data });

      await driversRef
        .doc(driverId)
        .collection("info")
        .doc("current")
        .update(data);
      await driversRef.doc(driverId).update(data);

      set({ isRequestLoading: false });
    } catch (error) {
      console.log(error);
      toast.error("Erro ao atualizar valor da corrida.");
      set({ isRequestLoading: false });
    }
  },

  setLoading: (isLoading) => set({ isLoading }),
}));
