import { styled } from "@material-ui/core";

export const AddressText = styled("p")({
  textOverflow: "ellipsis",
  overflow: "hidden",
  lineClamp: 2,
  minHeight: 60,
  minWidth: 150,
  display: "-webkit-box",
  WebkitLineClamp: 4,
  WebkitBoxOrient: "vertical",
});
