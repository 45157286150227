import { useState } from "react";
import moment from "moment";
import "moment/locale/pt-br";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut, Pie } from "react-chartjs-2";
ChartJS.register(ArcElement, Tooltip, Legend);

// hooks
import { useStores } from "stores";
// components
import { DataTable } from "./components/DataTable";

export function Reports() {
  const { reports, auth } = useStores();
  const classes = useStyles();

  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");

  const currentYear = moment().year();
  const years = Array.from({ length: 3 }, (_, i) => currentYear - i);
  const months = moment.months();
  const report = reports.reportData;
  const isFull = auth.userData?.access_type === "full";

  const handleGenerateReport = () => {
    reports.generateReport(selectedMonth, selectedYear);
  };

  return (
    <Container>
      <Paper className={classes.paper}>
        <Typography variant="h6">Relatórios</Typography>
        <Box className={classes.row}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="month">Mês</InputLabel>
            <Select
              labelId="month"
              id="month"
              value={selectedMonth}
              onChange={({ target }) =>
                setSelectedMonth(target.value as string)
              }
              label="Mês"
            >
              <MenuItem value="">
                <em>Selecione</em>
              </MenuItem>
              {months.map((month, index) => (
                <MenuItem key={month} value={index}>
                  {month}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="year">Ano</InputLabel>
            <Select
              labelId="year"
              id="year"
              label="Ano"
              value={selectedYear}
              onChange={({ target }) => setSelectedYear(target.value as string)}
            >
              <MenuItem value="">
                <em>Selecione</em>
              </MenuItem>
              {years.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            color="primary"
            variant="contained"
            size="large"
            disabled={!selectedMonth || !selectedYear || reports.isLoading}
            onClick={handleGenerateReport}
          >
            {reports.isLoading ? (
              <CircularProgress size={18} />
            ) : (
              "Gerar relatório"
            )}
          </Button>
        </Box>
        {!!report && (
          <>
            {isFull && (
              <Grid container sm>
                <Grid item xs={12} md>
                  <Doughnut
                    style={{
                      maxWidth: 300,
                      maxHeight: 250,
                    }}
                    data={{
                      labels: ["Pix", "Dinheiro"],
                      datasets: [
                        {
                          label: " ",
                          data: [
                            report.payModeCount?.pix,
                            report.payModeCount?.money,
                          ],
                          backgroundColor: [
                            "rgba(54, 162, 235, 0.5)",
                            "rgba(255, 206, 86, 0.5)",
                          ],
                          borderColor: [
                            "rgba(54, 162, 235, 1)",
                            "rgba(255, 206, 86, 1)",
                          ],
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={{
                      plugins: {
                        legend: {
                          display: true,
                          position: "right",
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md>
                  <Pie
                    style={{
                      maxWidth: 300,
                      maxHeight: 250,
                    }}
                    data={{
                      labels: ["Finalizadas", "Canceldas", "Canc. Piloto"],
                      datasets: [
                        {
                          label: " ",
                          data: [
                            report.totalFinishedRides,
                            report.totalCancelledRides,
                            report.totalMotoCancelledPilot +
                              report.totalCarCancelledPilot,
                          ],
                          backgroundColor: [
                            "rgba(75, 192, 192, 0.5)",
                            "rgba(255, 206, 86, 0.5)",
                            "rgba(255, 99, 132, 0.5)",
                          ],
                          borderColor: [
                            "rgba(75, 192, 192, 1)",
                            "rgba(255, 206, 86, 1)",
                            "rgba(255, 99, 132, 0.1)",
                          ],
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={{
                      plugins: {
                        legend: {
                          display: true,
                          position: "right",
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
            )}
            <DataTable report={report} />
          </>
        )}
      </Paper>
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    marginLeft: 0,
    minWidth: 200,
  },
  paper: {
    padding: theme.spacing(2),
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
}));
