import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";

// hooks
import { useStores } from "stores";

type Props = {
  open: boolean;
  driverData: DriverData;
  onClose(): void;
};

export function RechargeModal({ open, driverData, onClose }: Props) {
  const { financial } = useStores();

  const [amount, setAmount] = useState(20);

  const handleRecharge = async () => {
    financial.rechargeDriverBalance(driverData, amount, onClose);
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        Recarga para {driverData.first_name} {driverData.last_name}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>Escolha o valor da recarga.</DialogContentText>
        <FormControl component="fieldset">
          <RadioGroup
            name="amount"
            value={amount}
            onChange={({ target }) => setAmount(Number(target.value))}
          >
            <FormControlLabel value={20} control={<Radio />} label="R$ 20" />
            <FormControlLabel value={40} control={<Radio />} label="R$ 40" />
            <FormControlLabel value={60} control={<Radio />} label="R$ 60" />
            <FormControlLabel value={80} control={<Radio />} label="R$ 80" />
            <FormControlLabel value={100} control={<Radio />} label="R$ 100" />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancelar
        </Button>
        <Button onClick={handleRecharge} color="primary">
          Confimar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
