import { useEffect } from "react";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "moment/locale/pt-br";
import "react-toastify/dist/ReactToastify.css";

// views
import {
  Dashboard,
  Login,
  Rides,
  Drivers,
  Users,
  Financial,
  Rates,
  Feedbacks,
  Reports,
} from "views";
// components
import { ProtectedRoute } from "components";
// hooks
import { useStores } from "stores";

function App() {
  // hooks
  const history = useHistory();
  const { auth, ride } = useStores();

  // constants
  const isFullAccess = auth.userData?.permission_type === "full";

  const handleNavigate = () => {
    history?.push("/login");
  };

  useEffect(() => {
    if (!auth.userData) return;

    const removeDrivers = ride.loadRides();

    return () => {
      removeDrivers();
    };
  }, [auth.userData]);

  useEffect(() => {
    !isFullAccess && auth.updateUserData(handleNavigate);
  }, []);

  return (
    <>
      <BrowserRouter>
        <Switch>
          <ProtectedRoute exact component={Dashboard} path="/" />
          <ProtectedRoute exact component={Rates} path="/rates" />
          <ProtectedRoute exact component={Rides} path="/rides" />
          <ProtectedRoute exact component={Drivers} path="/drivers" />
          <ProtectedRoute exact component={Users} path="/users" />
          <ProtectedRoute exact component={Feedbacks} path="/feedbacks" />
          <ProtectedRoute exact component={Financial} path="/financial" />
          <ProtectedRoute exact component={Reports} path="/reports" />
          <Route component={Login} path="/login" />
        </Switch>
      </BrowserRouter>
      <ToastContainer />
    </>
  );
}

export default App;
