import { useEffect } from "react";
import MaterialTable, { Column } from "material-table";
import { Button } from "@material-ui/core";
import moment from "moment";

// assets
import profile_pic from "assets/profile.png";
// components
import { Toolbar } from "./components/Toolbar";
import { CircularLoading } from "components";
// hooks
import { useStores } from "stores";

export function Users() {
  // hooks
  const { auth, user } = useStores();

  useEffect(() => {
    user.loadUsers();
    user.getUsersCount();
  }, []);

  const isFullAccess = auth.userData?.access_type === "full";

  const handleLoadMore = () => {
    const loadLimit = user.loadLimit + 60;
    user.loadUsers(loadLimit);
  };

  const renderDate = (row) => {
    let date = row.created_at;
    if (row.created_at.seconds) {
      date = new Date(row.created_at.toDate()).toUTCString();
    }
    return <p>{moment(date).locale("pt-br").format("D [de] MMM, YYYY")}</p>;
  };

  const formatDate = (createdAt) => {
    let date = createdAt;
    if (createdAt.seconds) {
      date = new Date(createdAt.toDate()).toUTCString();
    }

    return date;
  };

  const columns: Column<UserData>[] = [
    {
      title: "Foto",
      field: "profile_image",
      editable: "never",
      align: "center",
      render: (rowData) => (
        <img
          alt="Profile"
          src={rowData.profile_image || profile_pic}
          style={{ width: 50, borderRadius: "50%" }}
        />
      ),
    },
    {
      title: "Nome",
      field: "first_name",
      editable: "never",
      render: (rowData) => (
        <p>
          {rowData.first_name} {rowData.last_name}
        </p>
      ),
    },
    ...(isFullAccess
      ? [
          { title: "Telefone", field: "phone" },
          { title: "Email", field: "email" },
        ]
      : []),
    {
      title: "Cadastro",
      field: "created_at",
      customSort: (a, b) =>
        new Date(formatDate(a.created_at)).getTime() -
        new Date(formatDate(b.created_at)).getTime(),
      render: renderDate,
    },
  ];

  return (
    <>
      <Toolbar
        searchByName={user.searchByName}
        resetData={user.loadUsers}
        usersCont={user.usersCount}
        todayUsersCount={user.todayUsersCount}
      />
      {user.isLoading ? (
        <CircularLoading />
      ) : (
        <MaterialTable
          columns={columns}
          data={user.usersData}
          options={{
            pageSize: user.usersData.length,
            pageSizeOptions: [20, 50, 150],
            emptyRowsWhenPaging: false,
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "Nenhuma usuário encontrado",
            },
          }}
          components={{
            Toolbar: () => null,
            Pagination: () => (
              <Button
                onClick={handleLoadMore}
                color="primary"
                variant="contained"
                disabled={user.usersData.length === user.usersCount}
                style={{ margin: 20 }}
              >
                {user.usersData.length} de {user.usersCount.toLocaleString()} -
                Carregar mais...
              </Button>
            ),
          }}
        />
      )}
    </>
  );
}
