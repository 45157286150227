import { Route, Redirect } from "react-router-dom";

import ResponsiveDrawer from "./ResponsiveDrawer";

import { useStores } from "stores";

export function ProtectedRoute({ component: Component, ...rest }) {
  const { auth } = useStores();

  return (
    <Route
      {...rest}
      render={(props) =>
        auth.userData ? (
          <ResponsiveDrawer>
            <Component {...props} />
          </ResponsiveDrawer>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}
