import { useAuthStore } from "./auth.store";
import { useDriverStore } from "./driver.store";
import { useFeedbacksStore } from "./feedbacks.store";
import { useFinancialStore } from "./financial.store";
import { useReportsStore } from "./reports.store";
import { useRideStore } from "./ride.store";
import { useUserStore } from "./users.store";

export const useStores = () => {
  const auth = useAuthStore();
  const ride = useRideStore();
  const driver = useDriverStore();
  const user = useUserStore();
  const financial = useFinancialStore();
  const feedbacks = useFeedbacksStore();
  const reports = useReportsStore();

  return {
    auth,
    ride,
    driver,
    user,
    financial,
    feedbacks,
    reports,
  };
};
