import { CircularProgress, Grid } from "@material-ui/core";

export function CircularLoading() {
  return (
    <Grid
      style={{
        minHeight: "calc(100vh - 80px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color="primary" />
    </Grid>
  );
}
