import { useEffect, useState } from "react";
import MaterialTable, { Column } from "material-table";
import { Grid, Typography } from "@material-ui/core";
import { LocalTaxi, TwoWheeler } from "@material-ui/icons";
import moment from "moment";

// components
import { CircularLoading, DashboardCard } from "components";
// hooks
import { useStores } from "stores";
import { brlConverter } from "helpers";

export function Financial() {
  //hooks
  const { auth, ride, financial } = useStores();

  // state
  const [showDay, setShowDay] = useState(true);
  const [showMonth, setShowMonth] = useState(true);

  // constants
  const isFullAccess = auth.userData?.permission_type === "full";
  const accessType = auth.userData?.access_type;
  const { todayGains, totalGains, todayTaxGains, totalTaxGains } = ride;
  const totalCount = financial.recharges.length.toLocaleString();
  const totalAmount = brlConverter(financial.rechargesAmount);
  const carCount = financial.carRecharges.toLocaleString();
  const carAmount = brlConverter(financial.carRechargesAmount);
  const motoCount = financial.motoRecharges.toLocaleString();
  const motoAmount = brlConverter(financial.motoRechargesAmount);

  const getInfo = () => {
    const info = {
      full: `Recargas esse mês: ${totalCount} - Carro: ${carCount} - Moto ${motoCount} | Valor: ${totalAmount} - Carro: ${carAmount} - Moto: ${motoAmount}`,
      car: `Recargas esse mês: ${carCount} - Valor: ${carAmount}`,
      moto: `Recargas esse mês: ${motoCount} - Valor: ${motoAmount}`,
    };

    return info[accessType];
  };

  useEffect(() => {
    financial.loadRecharges();
  }, []);

  const renderDate = (row) => {
    const date = new Date(row.date.toDate()).toUTCString();
    return <p>{moment(date).locale("pt-br").format("D [de] MMM, YYYY")}</p>;
  };

  const formatDate = (createdAt) => {
    let date = createdAt;
    if (createdAt.seconds) {
      date = new Date(createdAt.toDate()).toUTCString();
    }

    return date;
  };

  const columns: Column<Recharge>[] = [
    ...(isFullAccess
      ? [
          {
            title: "Tipo",
            field: "type",
            customSort: (a, b) => {
              a.type = a.type || "moto";
              b.type = b.type || "moto";
              return a.type.localeCompare(b.type);
            },
            render: (row) =>
              row.type === "car" ? <LocalTaxi /> : <TwoWheeler />,
          },
        ]
      : []),
    { title: "Nome", render: (row) => row.pilot_name || row.driver_name },
    {
      title: "Valor",
      render: (row) => (
        <>
          {brlConverter(row.amount)} <br />
          {brlConverter(row.charged_fee)}
        </>
      ),
    },
    {
      title: "Status",
      render: (row) => {
        switch (row.status) {
          case "PAID":
            return "Pago";
          case "PENDING":
            return "Pendente";
          case "EXPIRED":
            return "Expirado";
          default:
            return "";
        }
      },
    },
    { title: "Operador", field: "operator" },
    {
      title: "Data recarga",
      customSort: (a, b) =>
        new Date(formatDate(a.date)).getTime() -
        new Date(formatDate(b.date)).getTime(),
      render: renderDate,
    },
  ];

  return financial.isLoading ? (
    <CircularLoading />
  ) : (
    <>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} md>
          <DashboardCard
            isVisible={showDay}
            showHide={() => setShowDay(!showDay)}
            title={`Ganhos dia: ${
              showDay ? brlConverter(todayTaxGains) : "****"
            }`}
          >
            {`Movimentação dia: ${
              showDay ? brlConverter(todayGains) : "*****"
            }`}
          </DashboardCard>
        </Grid>
        <Grid item xs={12} md>
          <DashboardCard
            title={`Ganhos mês: ${
              showMonth ? brlConverter(totalTaxGains) : "****"
            }`}
            isVisible={showMonth}
            showHide={() => setShowMonth(!showMonth)}
          >
            {`Movimentação mês: ${
              showMonth ? brlConverter(totalGains) : "*****"
            }`}
          </DashboardCard>
        </Grid>
        <Grid item xl={12} md={12} sm={12} xs={12}>
          <Typography variant="h6">{getInfo()}</Typography>
        </Grid>
      </Grid>
      <MaterialTable
        title="Recargas"
        columns={columns}
        data={financial.recharges}
        options={{
          pageSize: 150,
          pageSizeOptions: [20, 50, 150],
          emptyRowsWhenPaging: false,
        }}
        localization={{
          toolbar: { searchPlaceholder: "Pesquisar" },
          header: { actions: "Ações" },
          body: {
            editRow: { deleteText: "Excluir esta recarga ?" },
            emptyDataSourceMessage: "Nenhuma recarga encontrada",
          },
          pagination: {
            labelRowsSelect: "Resultados",
            labelDisplayedRows: "{from}-{to} de {count}",
            firstTooltip: "Primeira página",
            previousTooltip: "Página anterior",
            nextTooltip: "Próxima página",
            lastTooltip: "Última página",
          },
        }}
        editable={{
          ...(isFullAccess && {
            onRowDelete: (oldData: Recharge) =>
              financial.deleteRecharge(oldData.id),
          }),
        }}
      />
    </>
  );
}
