import { ratesRef } from "config/firebase";

export const getTaxFare = async (
  rideCost: number,
  type: "car" | "moto" = "moto",
) => {
  const doc = await ratesRef.doc(type).get();
  const { tax_rate } = doc.data();
  const withoutPercent = rideCost - (rideCost * tax_rate) / 100;

  return {
    tax_fare: rideCost - withoutPercent,
    percentage: tax_rate,
  };
};

export const rideStatus = {
  START: "Iniciada",
  END: "Finalizada",
  CANCELLED: "Cancelada",
  CANCELLED_PILOT: "Cancelada pelo piloto",
  PENDING: "Pendente",
  ACCEPTED: "Aceita",
  ARRIVED: "Chegou no local",
  NEW: "Nova",
  EXPIRED: "Expirada",
};

export const brlConverter = (amount = 0) => {
  return `R$ ${amount.toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
  })}`;
};

export const numberFormater = (value: number) => {
  return value.toLocaleString();
};

export const getTaxAmount = (cost: number, taxRate = 12) => {
  const amount = cost - (cost * taxRate) / 100;
  return cost - amount;
};
